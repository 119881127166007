<template>
	<div class="container">
		<el-form :model="form" :rules="rules" ref="form" label-position="top" :hide-required-asterisk="true" class="form">
		    <div class="label">Shipping Address</div>
		    <el-row :gutter="10">
		        <el-col :xs="24" :sm="12" :md="12">
		            <el-form-item label="First name" prop="user_name_first">
		                <el-input v-model="form.user_name_first" placeholder=""></el-input>
		            </el-form-item>
		        </el-col>
		        <el-col :xs="24" :sm="12" :md="12">
		            <el-form-item label="Last name" prop="user_name_last">
		                <el-input v-model="form.user_name_last" placeholder=""></el-input>
		            </el-form-item>
		        </el-col>
		        <el-col :span="24">
		            <el-form-item label="Address" prop="address">
		                <el-input v-model="form.address" placeholder=""></el-input>
		            </el-form-item>
		        </el-col>
		        <el-col :span="24">
		            <el-form-item label="Apartment, suite, etc. (optional)" prop="apartment">
		                <el-input v-model="form.apartment" placeholder=""></el-input>
		            </el-form-item>
		        </el-col>
		        <el-col :xs="24" :sm="8" :md="8">
		            <el-form-item label="Country" prop="country">
                        <el-select v-model="form.country" filterable placeholder="" @change="changeCountry">
                            <el-option v-for="(item,index) in countries" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
		            </el-form-item>
		        </el-col>
		        <el-col :xs="24" :sm="8" :md="8">
		            <el-form-item label="State" prop="state">
                        <el-select v-model="form.state" filterable placeholder="" @change="changeState">
                            <el-option v-for="(item,index) in states" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
		            </el-form-item>
		        </el-col>
		        <el-col :xs="24" :sm="8" :md="8">
		            <el-form-item label="Postal code" prop="postal_code">
		                <el-input v-model="form.postal_code" placeholder=""></el-input>
		            </el-form-item>
		        </el-col>
		        <el-col :span="24">
		            <el-form-item label="City" prop="city">
                        <el-select v-model="form.city" filterable placeholder="">
                            <el-option v-for="(item,index) in cities" :key="index" :label="item.name" :value="item.name"></el-option>
                        </el-select>
		            </el-form-item>
		        </el-col>
		        <el-col :span="24">
		            <el-form-item label="Phone" prop="phone">
		                <el-input v-model="form.phone" placeholder=""></el-input>
		            </el-form-item>
		        </el-col>
		        <el-col :span="24">
		            <el-form-item>
		                <div class="operate">
		                    <div class="back" @click="back">
		                        <i class="el-icon-arrow-left el-icon--left"></i>
		                        Back to change
		                    </div>
		                    <div class="submit-btn" @click="submitForm">Submit</div>
		                </div>
		            </el-form-item>
		        </el-col>
		    </el-row>
		</el-form>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
				    user_name_first: '',
				    user_name_last: '',
				    address: '',
				    apartment: '',
				    country: '',
				    state: '',
				    postal_code: '',
				    city: '',
				    phone: ''
				},
                countries: [],
                states: [],
                cities: [],
				rules: {
				    user_name_first: [
				        {
				            required: true,
				            message: 'Please enter first name',
				            trigger: 'blur'
				        }
				    ],
				    user_name_last: [
				        {
				            required: true,
				            message: 'Please enter last name',
				            trigger: 'blur'
				        }
				    ],
				    address: [
				        {
				            required: true,
				            message: 'Please enter address',
				            trigger: 'blur'
				        }
				    ],
				    country: [
				        {
				            required: true,
				            message: 'Please enter country',
				            trigger: 'blur'
				        }
				    ],
				    state: [
				        {
				            required: true,
				            message: 'Please enter state',
				            trigger: 'blur'
				        }
				    ],
				    postal_code: [
				        {
				            required: true,
				            message: 'Please enter postal code',
				            trigger: 'blur'
				        }
				    ],
				    city: [
				        {
				            required: true,
				            message: 'Please enter city',
				            trigger: 'blur'
				        }
				    ],
				    phone: [
				        {
				            required: true,
				            message: 'Please enter phone',
				            trigger: 'blur'
				        }
				    ]
				}
			}
		},
        created() {
            let address_id = this.$route.query.id
            if(address_id) {
                this.form.id = address_id
                this.getDetail()
            }
            this.getCountries()
        },
        computed: {
            curAddressId() {
                return this.$route.query.id
            }
        },
        watch: {
            curAddressId(val) {
                // console.log(val)
                if(val) {
                    this.form.id = val
                    this.getDetail()
                } else {
                    this.form.id = undefined
                }
            }
        },
		methods: {
            getDetail() {
                this.$api.address_detail({
                    id: this.form.id
                },true).then(res => {
                    Object.keys(this.form).forEach(key => {
                         this.form[key] = res.data[key]
                    })
                })
            },
            
            getCountries() {
                this.$api.countries({},false,259200000).then(res => {
                    this.countries = res.data
                })
            },
            
            changeCountry(country_id) {
                this.$api.states({
                    'filters[country_id]': country_id
                },true).then(res => {
                    this.states = res.data
                })
            },
            
            changeState(state_id) {
                this.$api.cities({
                    'filters[state_id]': state_id
                },true).then(res => {
                    this.cities = res.data
                })
            },
            
			submitForm() {
			    this.$refs.form.validate((valid) => {
			        if(valid) {
                        this.form.country = this.countries.find(item => item.id == this.form.country).name
                        this.form.state = this.states.find(item => item.id == this.form.state).name
                        this.$api.createAddress(this.form,true).then(res => {
                            this.$message({
                                message: res.message,
                                type: 'success'
                            })
                            this.back()
                        })
			        }
			    })
			},
			
			back() {
			    this.$router.go(-1)
			}
		}
	}
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .container {
        	width: 100%;
        	min-height: 100vh;
        	box-sizing: border-box;
        	padding: 5rem 0 4rem;
        	
        	.form {
        	    width: 60rem;
        		margin: 0 auto;
        	    
        	    .label {
        	        width: 100%;
        	        margin-bottom: 2.5rem;
        	        font-size: 1.8rem;
        	        color: #333333;
        	        word-wrap: break-word;
        	    }
        	    
        	    /deep/ .el-form-item {
        	        margin-bottom: 2.5rem;
        	    }
        	    
        	    .operate {
        	        display: flex;
        	        justify-content: space-between;
        	        align-items: center;
        	        width: 100%;
        	        margin-top: .8rem;
        	        
        	        .back {
        	            font-size: 1.4rem;
        	            color: #333333;
        	            white-space: nowrap;
        	            cursor: pointer;
        	        }
        	        
        	        .submit-btn {
        	            min-width: 12rem;
        	            height: 6rem;
        	            line-height: 6rem;
        	            box-sizing: border-box;
        	            padding: 0 2.5rem;
        	            border-radius: 4px;
        	            font-size: 1.5rem;
        	            color: #FFFFFF;
        	            text-align: center;
        	            white-space: nowrap;
        	            background-color: #000000;
        	            cursor: pointer;
        	        }
        	    }
        	}
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .container {
        	width: 100%;
        	min-height: 100vh;
        	box-sizing: border-box;
        	padding: 2.5rem $h5-box-padding;
        	
        	.form {
                width: 100%;
        	    
        	    .label {
        	        width: 100%;
        	        margin-bottom: 2rem;
        	        font-size: 1.8rem;
        	        color: #333333;
        	        word-wrap: break-word;
        	    }
        	    
        	    /deep/ .el-form-item {
        	        margin-bottom: 2rem;
        	    }
        	    
        	    .operate {
        	        display: flex;
        	        flex-direction: column-reverse;
        	        align-items: center;
        	        width: 100%;
        	        margin-top: .8rem;
        	        
        	        .back {
        	            margin-top: .8rem;
        	            font-size: 1.4rem;
        	            color: #333333;
        	            white-space: nowrap;
        	            cursor: pointer;
        	        }
        	        
        	        .submit-btn {
        	            width: 100%;
        	            height: 5.5rem;
        	            line-height: 5.5rem;
        	            box-sizing: border-box;
        	            padding: 0 2rem;
        	            border-radius: 4px;
        	            font-size: 1.5rem;
        	            color: #FFFFFF;
        	            text-align: center;
        	            white-space: nowrap;
        	            background-color: #000000;
        	            cursor: pointer;
        	        }
        	    }
        	}
        }
    }
</style>